<template>
  <div>
      <div class="device-info">
          <div class="shadow-alert">
            <b-avatar variant="light" class="shadow-alert">
              <iconMedic v-if="status === 'M'"/>
              <iconPolice v-else-if="status === 'P'"/>
              <iconFire v-else-if="status === 'B'"/>
              <iconContact v-else-if="status === 'C'"/>
              <iconNoAlert v-else/>
            </b-avatar>
          </div>
          <div class="device-text-container">
            <span class="main-info" v-if="status === 'M'">{{$t('Messages.MedicEmergency')}}</span>
            <span class="main-info" v-else-if="status === 'P'">{{$t('Messages.Police')}}</span>
            <span class="main-info" v-else-if="status === 'B'">{{$t('Messages.Firefighters')}}</span>
            <span class="main-info" v-else-if="status === 'C'">{{$t('Messages.Contact')}}</span>
            <span class="main-info" v-else>{{$t('Messages.NoAlert')}}</span>
            <span class="subtext-info">{{$t("Messages.State")}}</span>
          </div>
      </div>
  </div>
</template>
<script>
import { BAvatar } from 'bootstrap-vue'
import iconContact from '@core/spore-components/icons/iconContact'
import iconFire from '@core/spore-components/icons/iconFire'
import iconPolice from '@core/spore-components/icons/iconPolice'
import iconMedic from '@core/spore-components/icons/iconMedic'
import iconNoAlert from '@core/spore-components/icons/iconNoAlert'

export default {
  components: {
    BAvatar,
    iconContact,
    iconFire,
    iconPolice,
    iconMedic,
    iconNoAlert
  },
  props: {
    status: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/spore-components/alertItem";
</style>
